import { Height } from "@mui/icons-material";
import ViewWorkOrder from "../pages/viewWorkOrder";

const translationsEn = {
  login: {
    forgot: "Forgot password? Reset here!",
    username: "Username",
    password: "Password",
    signIn: "Sign in",
    authFail: "Authentication failed. Please try again.",
  },
  forgotPassword: {
    letter: "Please insert your username to recive a password reset link!",
  },
  sidebar: {
    dashboard: "Dashboard",
    employeeOverview: "Employee overview",
    employeeManagement: "Employee management",
    employees: "Employees",
    shift: "Shift records",
    callDuty: "Call Duty",
    overtimes: "Overtimes",
    requests: "Requests",
    teamHistory: "Team history request",
    myRequest: "My requests",
    absence: "Absence request",
    teams: "Teams",
    inventory: "Inventory",
    userInventory: "User Inventory",
    inventoryManagement: "Inventory Management",
    cv: "CV",
    travelRequests: "Travel requests",
    myEquipment: "My equipment",
    admim: "Admin",
    companies: "Companies",
    active: "Active duties",
    scheduler: "Scheduler",
    equipment: "Equipment",
    budget: "Budget",
    shifts: "Shifts",
    workOrders: "Work orders",
    vehicles: "Vehicles",
  },
  dashboard: {
    employeeOverview: "Overview",
    employees: "Employees",
    myRequest: "My requests",
    team: "Teams",
    cv: "Cv",
  },
  teams: {
    name: "Name",
    lastName: "Last Name",
    active: "Active",
    company: "Company",
    select: "Select",
    teams: "Teams",
  },
  travelRequests: {
    title: "Travel requests",
    createRequest: "Create new travel request",
    createText:
      "This option allows you to create new travel requests. Each request can be saved or edited.",
    addRequest: "Add new travel request",
    myRequests: "My travel requests",
    myText:
      "This option allows you to view all your travel requests and edit them.",
    viewMyRequests: "View your travel requests",
    allTravelRequests: "All travel requests",
    allText:
      "This option allows you to see all the travel requests from your employees.",
    viewAllRequests: "View all travel requests",
  },
  createTravelRequests: {
    travelOrder: "Travel order",
    submit: "Submit",
    new: "Create new travel request",
    edit: "Edit travel request",
    generalInformation: "General information",
    additionalInformation: "Additional information",
    travelExpenses: "Travel expenses",
    travelReport: "Travel report",
    user: "User",
    chooseUser: "Choose user",
    company: "Company",
    chooseCompany: "Choose company",
    travelsDay: "Travels day",
    travelTo: "Travel to",
    travelCanLast: "Travel can last(days)",
    advancePayment: "Advance payment approved(€)",
    create: "Create",
    editButton: "Edit",
    cancel: "Cancel",
    departureDate: "Departure date",
    departureTime: "Departure time",
    returnDate: "Return date",
    returnTime: "Return time",
    durationHours: "Duration hours",
    dailyAlowances: "Number of daily allowances",
    dailyAmount: "Daily per diem amount(€)",
    layUpClaim: "Lay up claim(€)",
    applyAdvance: "Apply advance",
    restForPayment: "Rest for payment",
    currentDate: "Current date",
    responsiblePerson: "Responsible person",
    inputResponsiblePerson: "Input responsible person",
    submittedBy: "Submitted by",
    inputPerson: "Input person",
    typeOfTransportation: "Type of transportation",
    personalVehicle: "Used personal vehicle",
    brandAndType: "Brand and type",
    registrationNumber: "Registration number",
    pricePerKm: "Price km/eur",
    startDate: "Start date",
    endDate: "End date",
    ecoScore: "Eco score",
    km: "Km",
    totalOfEuros: "Total of euros(€)",
    additionalTransportationExpenses: "Additional transportation expenses",
    totalAdditionalTransportationExpenses:
      "Total eur additional transportation expenses",
    descriptionOfAdditionalCosts: "Description of additional costs",
    totalTransportationExpenses: "Total transportation expenses",
    addCost: "Add cost",
    description: "Report on the results of the next trip",
    reportsTo: "Reports to",
    reporter: "Reporter",
    uploadFiles: "Upload files",
    chooseTypeOfTransportation: "Choose type of transportation",
    files: "Files",
  },
  addCost: {
    title: "Add cost",
    costName: "Cost name",
    numberOf: "Number",
    amountOfCost: "Amount",
    totalAmount: "Total amount(€)",
    description: "Description",
  },
  myTravelRequests: {
    title: "My travel requests",
    status: "Status",
    travelRequests: "Travel requests",
    number: "Number",
    trTitle: "Title",
    action: "Action",
  },
  allTravelRequests: {
    title: "All travel requests",
    status: "Status",
    travelRequests: "Travel requests",
    number: "Number",
    trTitle: "Title",
    traveler: "Traveler",
    created: "Created",
    dateFrom: "Date from",
    dateTo: "Date to",
    person: "Person",
    action: "Action",
  },
  confirmationModal: {
    title: " travel request",
    note: "Are you sure you want to proceed with this action? This action can not be undone.",
    yes: "Yes",
    no: "No",
  },
  equipment: {
    equipment: "Equipment",
    myEquipment: "My equipment",
    createButton: "Create new equipment",
    equipmentStatus: "Equipment status",
    status: "Status",
    name: "Name",
    price: "Price(€)",
    warranty: "Warranty",
    usingFrom: "Using from",
    quantity: "Quantity",
    assignedTo: "Assigned to",
    lastEdit: "Last edit",
    description: "Description",
    action: "Action",
    create: "Create new equipment",
    edit: "Edit equipment",
    warrantyStart: "Warranty start",
    warrantyEnd: "Warranty end",
    files: "Files",
  },
  vehicles: {
    pageName: "Vehicles",
    createNewButton: "Create new vehicle",
    vehicleName: "Vehicle name",
    vehicleOwner: "Vehicle owner",
    assignedUser: "Assigned user",
    vehicleCategory: "Vehicle category",
    enviroment: "Enviroment class of the vehicle",
    maximumSpeed: "Max speed",
    vehicleStatus: "Vehicle status",
    firstRegistrationYear: "First registration year",
    licencePlateNo: "Licence plate",
    maximumAuthorizedMass: "Maximum autorized mass",
    category: "Category",
    numberOfSeats: "Number of seats",
    ecoCategory: "ECO category",
    success: "New vehicle added!",
    newVehicle: "New vehicle",
    vehicleUser: "Vehicle user",
    homologation: "Homologation type",
    brand: "Brand",
    commercial: "Commerical name",
    vinNo: "Vin number",
    tehnical: "Tehnical max allowed mass",
    curb: "Curb weight",
    lastYearRegistration: "Last year registration",
    displacement: "Displacement",
    engineHorsepower: "Engine horsepower",
    fuelType: "Fuel type",
    standingCapacity: "Standing capacity",
    stationaryNoise: "Stationary noise(db)",
    co2: "CO2 Emission",
    ownerOIB: "Owner OIB",
    bodyType: "Body Type",
    purpose: "Purpose",
    firstRegistrationInCountry: "First registration in country",
    permissiblePayload: "Permissible payload",
    length: "Lenght",
    width: "Width",
    height: "Height",
    tireSize: "Tire size",
    make: "Make/Model",
    KmOnInput: "KM on input day",
    addService: "Add vehicle service",
    deleteVehicle: "Delete vehicle",
  },

  company: {
    button: "Create company",
    pageName: "Company",
    name: "Name",
    oib: "OIB",
    address: "Address",
    director: "Director",
    internal: "Internal",
    actions: "Edit",
  },
  cv: {
    name: "CV",
    viewCv: "CV Overview",
    viewAllCv: "View all cvs",
    summary: "Summary",
    education: "Education",
    languages: "Languages",
    experiences: "Experiences",
    projects: "Projects",
    licences: "Certificates and Licenses",
    publics: "Publications",
    skills: "Skills",
    honours: "Honours and Awards",
    create: "Create CV",
    note: "Do you want to create CV?",
    cvWrittingLanguage: "Writting language",
    createNote: "Please create your cv",
  },
  cvModals: {
    addSummary: "Add summary",
    addEducation: "Add education",
    onGoingEducation: "Ongoing education",
    addLanguage: "Add language",
    addExperience: "Add experience",
    onGoingExperience: "Ongoing experience",
    addProject: "Add project",
    onGoingProject: "Ongoing project",
    addCertificates: "Add certificates and licenses",
    addPublications: "Add publication",
    addSkills: "Add skills",
    addSkill: "Add new skill",
    addHonours: "Add honours and awards",
    startDate: "Start date",
    endDate: "End date",
    startMonth: "Start month",
    endMonth: "End month",
    eventYear: "Event year",
    eventMonth: "Event month",
    issuerYear: "Issuer year",
    issuerMonth: "Issuer month",
    noSummary: "No summary available",
    school: "School",
    degree: "Degree",
    fieldOfStudy: "Field of study",
    description: "Description",
    noEducation: "No education available",
    noLanguage: "No language available",
    compay: "Company",
    title: "Title",
    contactNumber: "Contact number",
    noExperience: "No experience available",
    role: "Role",
    shortDescription: "Short description of the project",
    responsibilitiesDescriptiom: "Description of responsibilities",
    techStack: "Tech stack",
    noProject: "No project available",
    certificateName: "Certificate name",
    certificate: "Certificate ID",
    organization: "Issuing organization",
    noCertificate: "No certificate available",
    url: "Url",
    publicationName: "Publication name",
    eventName: "Event name",
    noPublication: "No publication available",
    selectSkill: "Select skill",
    noSkills: "No skills available",
    honorName: "Name",
    issuer: "Issuer",
    issuerDate: "Date",
    noHonor: "No honor or awards available",
    successCv: "CV created successfully!",
    successCertificates: "Certificate added successfully!",
    successAddSkill: "Skill added successfully!",
    successEducation: "Education added successfully!",
    successExpirience: "Experience added successfully!",
    successHonor: "Honour added successfully!",
    successSummary: "Summary created successfully!",
    successLanguage: "Language proficiency added successfully!",
    successProject: "Project added successfully!",
    succesPublication: "Publication added successfully!",
    summaryNote: "Please add your short summary.",
    viewAllCvs: "View all cvs",
  },
  employees: {
    personalInformation: "Personal Information",
    accountInformation: "Account Information",
    contractInformation: "Contract Information",
    create: "Create new employee",
    name: "Employees",
    firstName: "First name",
    lastName: "Last name",
    userName: "Username",
    businessPhone: "Business Phone",
    oib: "OIB",
    date: "Date of birth",
    email: "Email",
    company: "Company",
    username: "Username",
    shiftDuration: "Shift duration",
    vacationDays: "Vacation",
    status: "Status",
    role: "Role",
  },
  labels: {
    logoutMessage: "Are you sure you want to log out?",
    companyName: "Office Pal",
    person: "Person",
    year: "Year",
    equipment: "Equipment type",
    serialNumber: "Serial number",
    date: "Date",
    selectEmploye: "Select employee",
    startDate: "Start date",
    endDate: "End date",
    endDateContract: "End contract date (excluded)",
    month: "Month",
    from: "From",
    reasons: "Reasons",
    tickets: "Ticket reference",
    comment: "Comment",
    toDate: "To",
    status: "Status",
    budget: "Budget (kn)",
    availableBudget: "Available Budget (kn)",
    previousYearVacation: "Previous year vacation",
    currentYearVacation: "Current year vacation",
    currentPaidLeave: "Current paid leave days",
    currentBloodLeave: "Current year blood donation days",
    selectCompany: "Select company",
    active: "Active team",
    teamName: "Team name",
    probationDate: "Probation expiration date",
    dateOfBirth: "Date of birth",
    logout: "Log out",
    search: "Search",
  },
  buttons: {
    save: "Save",
    cancel: "Cancel",
    search: "Search",
    approve: "Approve",
    yes: "Yes",
    no: "No",
    delete: "Delete",
    edit: "Edit",
    create: "Create",
    member: "Member",
    approver: "Approver",
    next: "Next",
    cancelContract: "Cancel contract",
    logout: "LOGOUT",
    back: "Back",
    confirm: "Confirm",
    ok: "Ok",
    publish: "Publish",
    submit: "Submit",
    view: "View",
  },
  validation: {
    required: "Field is required",
    emailInvalid: "Email is invalid",
  },
  teamHistory: {
    name: "Team history request",
  },
  teamRequest: {
    name: "Add new employee",
    newRequest: "New request",
    editRequest: "Edit request",
    firstName: "First name",
    lastName: "Last name",
    id: "ID",
    type: "Type",
    status: "Status",
    author: "Author",
    created: "Created",
    dateFrom: "From",
    dateTo: "To",
    note: "Note",
    comment: "Comment",
  },
  myRequest: {
    name: "My requests",
    type: "Type",
    status: "Status",
    author: "Author",
    created: "Created",
    dateFrom: "From",
    dateTo: "To",
    note: "Note",
  },
  absenceRequest: {
    name: "Absence request",
    approveName: "Approve request ?",
    deniedName: "Denied request ?",
    comment: "Comment: ",
  },
  userInventory: {
    name: "My equipment",
    budget: "Budget",
    availibleBudget: "Availible Budget",
    equipmentName: "Name",
    description: "Description",
    location: "Location(room)",
    type: "Type",
    status: "Status",
    serialNumber: "Serial number",
    price: "Price(Kn)",
    warranty: "Warranty",
    usingFrom: "Using from",
  },
  table: {
    rb: "RB",
    id: "ID",
    firstName: "First name",
    lastName: "Last name",
    date: "Date",
    fromDate: "From",
    toDate: "To",
    reason: "Reason",
    ticket: "Ticket reference",
    comment: "Comment",
    budget: "Budget (kn)",
    availableBudget: "Available Budget (kn)",
    status: "Status",
    noData: "No data",
    company: "Company",
    dateOfBirth: "Date of birth",
    oib: "OIB",
    title: "Title",
    deadline: "Deadline",
    reporter: "Reporter",
    companyType: "Company type",
    assigned: "Assigned User",
    actions: "Actions",
  },
  modals: {
    deleteName: "Delete",
    deleteNote: "Do you want to continue with deleting this ?",
    saveName: "Save this ?",
    saveNote: "Do you  want to  continue with saving this ?",
    savedNote: "All Done !",
    savedName: "Save success !",
    cancelContract: "Are you sure you want to cancel contract?",
    cancelContractName: "Cancel contract?",
  },
  budget: {
    name: "Budget",
    modalName: "New budget",
  },
  overtime: {
    name: "Overtimes",
    addNewOvertime: "Add new overtimes",
  },
  workOrders: {
    noEmploye: "There is no employee",
    status: "Status",
    id: "ID",
    created: "Created",
    name: "Work orders",
    editName: "Edit work order",
    create: "Add new work order",
    newWorkOrders: "New work orders",
    title: "Title",
    reporter: "Reporter",
    companyType: "Company type",
    companyName: "Company name",
    assignedUser: "Assigned user",
    deadline: "Deadline",
    location: "Location/Address",
    description: "Description",
    time: "Deadline time",
    success: "Work order created!",
    error: "There was error!",
    editWorkOrders: "Edit work order",
    document: "Attached document",
    viewWorkOrder: "View work order",
  },

  teamsInformation: {
    name: "Name",
    previousYearVacation: "Previous Year Vacation: ",
    currentYearVacation: "Current Year Vacation: ",
    currentPaidLeave: "Current Paid Leave: ",
    currentBloodLeave: "Current Blood Leave: ",
    newTeam: "Create new team",
    editTeam: "Edit team",
    addNewMember: "Manage members",
    addNewMemberTitel: "Add new member to team !",
    chooseRole: "Choose employee role in team.",
    currentRemoteWorkLeave: "Remote work leave",
  },
  vacationDays: {
    MARRIAGE_LEAVE: "Marriage Leave",
    CHILD_BIRTH_LEAVE: "Child Birth Leave",
    FAMILY_SICKNESS_LEAVE: "Family Sickness Leave",
    FAMILY_MEMBER_DEATH_LEAVE: "Family Member Death Leave",
    RELOCATION_TO_THE_SAME_CITY: "Relocation to the Same City",
    RELOCATION_TO_THE_DIFFERENT_CITY: "Relocation to a Different City",
    NATURAL_DISASTER: "Natural Disaster",
    PROFESSIONAL_EDUCATION_LEAVE: "Professional Education Leave",
    PERSONAL_NEEDS: "Personal Needs",
    PARTNERS_FAMILY_MEMBER_DEATH_LEAVE: "Partner's Family Member Death Leave",
    OTHER_CLOSE_FAMILY_SICKNESS_LEAVE: "Other Close Family Sickness Leave",
    COURT_CALL_LEAVE: "Court Call Leave",
    CHILD_KINDERGARTEN_OR_SCHOOL_ADAPTATION_LEAVE:
      "Child Kindergarten or School Adaptation Leave",
  },

  rolesTypes: {
    ROLE_ADMIN: "Admin",
    ROLE_USER: "User",
  },

  statusType: {
    student: "Student",
    inactive: "Inactive",
    full_time: "Full time",
    part_time: "Part time",
  },
  status: {
    created: "Created",
    in_progress: "In progress",
    declined: "Declined",
    done: "Done",
    deleted: "Deleted",
  },
  editUser: {
    cancelContract: "Cancel contract",
    name: "Edit user",
    firstName: "First name",
    lastName: "Last name",
    oib: "OIB",
    date: "Date of birth",
    email: "Email",
    company: "Company",
    startDate: "Start date",
    endDate: "End date",
    shiftDuration: "Shift duration",
    vacationDays: "Vacation days",
    probatioDate: "Probation expiration date",
    employeeStatus: "Employee status",
    newContract: "New contract",
  },
  requestTypes: {
    vacation: "Vacation",
    blood_donation: "Blood Donation",
    parental_leave: "Parental Leave",
    remote_work: "Remote work",
    plan: "Plan",
    sick_leave: "Sick Leave",
    student_absence: "Student Absence",
    paid_leave: "Paid Leave",
  },
  statuses: {
    waiting_for_approval: "Waiting for approval",
    approved: "Approved",
    cancelled: "Cancelled",
  },
  activeDuty: {
    pageName: "Active Duty",
    modalAddName: "New active duty",
  },
};

export default translationsEn;
