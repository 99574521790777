import React, { useState } from "react";
import {
  Paper,
  Grid,
  TextField,
  Button,
  Typography,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  FormHelperText,
  styled,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import translationsEn from "../../i18n/translations_en";
import themeOptions from "../../style/componentsStyle";
import { useAlert } from "../../utils/alertContext";
import { createVehicle } from "../../api/vehiclesApi";
import { handleAttachFiles } from "../../utils/filesHandler";
import AttachFileIcon from "@mui/icons-material/AttachFile";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export const initialVehicleValues = {
  vehicleName: "",
  licencePlateNo: "",
  firstRegistrationYear: "",
  vehicleOwner: "",
  vehicleUserId: "",
  brand: "",
  homologationType: "",
  commercialName: "",
  vinNo: "",
  maximumAuthorizedMass: "",
  technicalMaxAllowedMass: 0,
  curbWeight: "",
  lastRegistrationYear: 0,
  category: "",
  numberOfAxles: 0,
  displacement: 0,
  engineHorsepower: 0,
  fuelType: "",
  numberOfSeats: 0,
  standingCapacity: 0,
  maxSpeed: 0,
  stationaryNoise: 0,
  co2Emission: 0,
  ecoCategory: 0,
  ownerOIB: "",
  bodyType: "",
  purpose: "",
  firstRegistrationInCountry: "",
  permissiblePayload: 0,
  length: 0,
  width: 0,
  height: 0,
  tyreSize: 0,
  model: "",
  kmOnInputDay: 0,
};

export default function NewVehicles() {
  const navigate = useNavigate();
  const { showAlert } = useAlert();

  const formik = useFormik({
    validationSchema: Yup.object().shape({
      vehicleName: Yup.string().required(translationsEn.validation.required),
      licencePlateNo: Yup.string().required(translationsEn.validation.required),
    }),
    initialValues: initialVehicleValues,

    onSubmit: async (values) => {
      try {
        await createVehicle({
          vehicleName: values.vehicleName,
          licencePlateNo: values.licencePlateNo,
          firstRegistrationYear: parseInt(values.firstRegistrationYear, 10),
          vehicleOwner: values.vehicleOwner,
          vehicleUserId: parseInt(values.vehicleUserId, 10),
          technicalMaxAllowedMass: values.technicalMaxAllowedMass,
          brand: values.brand,
          homologationType: values.homologationType,
          commercialName: values.commercialName,
          vinNo: values.vinNo,
          maximumAuthorizedMass: parseInt(values.maximumAuthorizedMass, 10),
          curbWeight: parseInt(values.curbWeight, 10),
          lastRegistrationYear: values.lastRegistrationYear,
          category: values.category,
          numberOfAxles: values.numberOfAxles,
          displacement: values.displacement,
          engineHorsepower: values.engineHorsepower,
          fuelType: values.fuelType,
          numberOfSeats: values.numberOfSeats,
          standingCapacity: values.standingCapacity,
          maxSpeed: values.maxSpeed,
          stationaryNoise: values.stationaryNoise,
          co2Emission: values.co2Emission,
          ecoCategory: values.ecoCategory,
          ownerOIB: values.ownerOIB,
          bodyType: values.bodyType,
          purpose: values.purpose,
          firstRegistrationInCountry: values.firstRegistrationInCountry,
          permissiblePayload: values.permissiblePayload,
          length: values.length,
          width: values.width,
          height: values.height,
          tyreSize: values.tyreSize,
          model: values.model,
          kmOnInputDay: values.kmOnInputDay,
        });
        showAlert(`${translationsEn.vehicles.success}`, "success");
        navigate("/vehicles");
      } catch (error) {
        console.error("Error creating vehicle:", error);
      }
    },
  });

  const handleClose = () => {
    formik.resetForm();
    navigate("/vehicles");
  };

  return (
    <>
      <Grid style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          style={{
            margin: "40px",
            fontSize: "30px",
            fontWeight: "bold",
            color: "#555555",
          }}
        >
          {translationsEn.vehicles.newVehicle}
        </Typography>

        <Grid style={{ display: "flex", alignItems: "center" }}>
          <Button
            style={{ padding: "5px" }}
            sx={{
              backgroundColor: themeOptions.palette.primary.main,
              color: "white",
              ":hover": {
                background: themeOptions.palette.primary.hover,
              },
              textTransform: "none",
            }}
            onClick={() => {
              console.log("Add service clicked for");
            }}
          >
            {translationsEn.vehicles.addService}
          </Button>
          <Grid
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "10px",
            }}
          >
            <Grid></Grid>
            <Button
              variant="outlined"
              startIcon={<AttachFileIcon />}
              sx={{
                color: themeOptions.palette.primary.main,
                borderColor: themeOptions.palette.primary.main,
                ":hover": {
                  background: themeOptions.palette.primary.hover,
                  color: "white",
                },
                borderRadius: "15px",
              }}
              component="label"
            >
              Attach document
              <VisuallyHiddenInput type="file" />
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Paper
        style={{
          padding: "20px",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          marginBottom: "20px",
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={3} style={{ maxWidth: "1500px" }}>
            <Grid item xs={6}>
              <TextField
                label={translationsEn.vehicles.vehicleName}
                id="vehicleName"
                name="vehicleName"
                value={formik.values.vehicleName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                error={
                  formik.touched.vehicleName &&
                  Boolean(formik.errors.vehicleName)
                }
                helperText={
                  formik.touched.vehicleName && formik.errors.vehicleName
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={translationsEn.vehicles.licencePlateNo}
                id="licencePlateNo"
                name="licencePlateNo"
                value={formik.values.licencePlateNo}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                error={
                  formik.touched.licencePlateNo &&
                  Boolean(formik.errors.licencePlateNo)
                }
                helperText={
                  formik.touched.licencePlateNo && formik.errors.licencePlateNo
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={translationsEn.vehicles.firstRegistrationYear}
                id="firstRegistrationYear"
                name="firstRegistrationYear"
                value={formik.values.firstRegistrationYear}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                error={
                  formik.touched.firstRegistrationYear &&
                  Boolean(formik.errors.firstRegistrationYear)
                }
                helperText={
                  formik.touched.firstRegistrationYear &&
                  formik.errors.firstRegistrationYear
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={translationsEn.vehicles.vehicleOwner}
                id="vehicleOwner"
                name="vehicleOwner"
                value={formik.values.vehicleOwner}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                error={
                  formik.touched.vehicleOwner &&
                  Boolean(formik.errors.vehicleOwner)
                }
                helperText={
                  formik.touched.vehicleOwner && formik.errors.vehicleOwner
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={translationsEn.vehicles.vehicleUser}
                id="vehicleUserId"
                name="vehicleUserId"
                value={formik.values.vehicleUserId}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                error={
                  formik.touched.vehicleUserId &&
                  Boolean(formik.errors.vehicleUserId)
                }
                helperText={
                  formik.touched.vehicleUserId && formik.errors.vehicleUserId
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={translationsEn.vehicles.brand}
                id="brand"
                name="brand"
                value={formik.values.brand}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                error={formik.touched.brand && Boolean(formik.errors.brand)}
                helperText={formik.touched.brand && formik.errors.brand}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl
                sx={{ width: "100%" }}
                error={Boolean(
                  formik.touched.homologationType &&
                    formik.errors.homologationType
                )}
              >
                <InputLabel id="demo-simple-select-label">
                  Homologation type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  label="Homologation type"
                  value={formik.values.homologationType}
                  onChange={(e: any) => {
                    formik.setFieldValue("homologationType", e.target.value);
                  }}
                >
                  <MenuItem value="Type1">Type 1</MenuItem>
                  <MenuItem value="Type2">Type 2</MenuItem>
                  <MenuItem value="Type3">Type 3</MenuItem>
                  <MenuItem value="Type4">Type 4</MenuItem>
                </Select>
                {formik.touched.homologationType &&
                  formik.errors.homologationType && (
                    <FormHelperText>
                      {formik.errors.homologationType as string}
                    </FormHelperText>
                  )}
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={translationsEn.vehicles.commercial}
                id="commercialName"
                name="commercialName"
                value={formik.values.commercialName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                error={
                  formik.touched.commercialName &&
                  Boolean(formik.errors.commercialName)
                }
                helperText={
                  formik.touched.commercialName && formik.errors.commercialName
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={translationsEn.vehicles.vinNo}
                id="vinNo"
                name="vinNo"
                value={formik.values.vinNo}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                error={formik.touched.vinNo && Boolean(formik.errors.vinNo)}
                helperText={formik.touched.vinNo && formik.errors.vinNo}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={translationsEn.vehicles.tehnical}
                id="technicalMaxAllowedMass"
                name="technicalMaxAllowedMass"
                value={formik.values.technicalMaxAllowedMass}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                error={
                  formik.touched.technicalMaxAllowedMass &&
                  Boolean(formik.errors.technicalMaxAllowedMass)
                }
                helperText={
                  formik.touched.technicalMaxAllowedMass &&
                  formik.errors.technicalMaxAllowedMass
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={translationsEn.vehicles.maximumAuthorizedMass}
                id="maximumAuthorizedMass"
                name="maximumAuthorizedMass"
                value={formik.values.maximumAuthorizedMass}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                error={
                  formik.touched.maximumAuthorizedMass &&
                  Boolean(formik.errors.maximumAuthorizedMass)
                }
                helperText={
                  formik.touched.maximumAuthorizedMass &&
                  formik.errors.maximumAuthorizedMass
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={translationsEn.vehicles.curb}
                id="curbWeight"
                name="curbWeight"
                value={formik.values.curbWeight}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                error={
                  formik.touched.curbWeight && Boolean(formik.errors.curbWeight)
                }
                helperText={
                  formik.touched.curbWeight && formik.errors.curbWeight
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={translationsEn.vehicles.lastYearRegistration}
                id="lastRegistrationYear"
                name="lastRegistrationYear"
                value={formik.values.lastRegistrationYear}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                error={
                  formik.touched.lastRegistrationYear &&
                  Boolean(formik.errors.lastRegistrationYear)
                }
                helperText={
                  formik.touched.lastRegistrationYear &&
                  formik.errors.lastRegistrationYear
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={translationsEn.vehicles.category}
                id="category"
                name="category"
                value={formik.values.category}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                error={
                  formik.touched.category && Boolean(formik.errors.category)
                }
                helperText={formik.touched.category && formik.errors.category}
              />
            </Grid>

            <Grid item xs={6}>
              <FormControl
                sx={{ width: "100%" }}
                error={Boolean(
                  formik.touched.numberOfAxles && formik.errors.numberOfAxles
                )}
              >
                <InputLabel>Number of axles</InputLabel>
                <Select
                  label="Number of axles"
                  value={formik.values.numberOfAxles}
                  onChange={(e: any) => {
                    formik.setFieldValue("numberOfAxles", e.target.value);
                  }}
                >
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                </Select>
                {formik.touched.numberOfAxles &&
                  formik.errors.numberOfAxles && (
                    <FormHelperText>
                      {formik.errors.numberOfAxles as string}
                    </FormHelperText>
                  )}
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <TextField
                label={translationsEn.vehicles.displacement}
                id="displacement"
                name="displacement"
                value={formik.values.displacement}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                error={
                  formik.touched.displacement &&
                  Boolean(formik.errors.displacement)
                }
                helperText={
                  formik.touched.displacement && formik.errors.displacement
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={translationsEn.vehicles.engineHorsepower}
                id="engineHorsepower"
                name="engineHorsepower"
                value={formik.values.engineHorsepower}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                error={
                  formik.touched.engineHorsepower &&
                  Boolean(formik.errors.engineHorsepower)
                }
                helperText={
                  formik.touched.engineHorsepower &&
                  formik.errors.engineHorsepower
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={translationsEn.vehicles.fuelType}
                id="fuelType"
                name="fuelType"
                value={formik.values.fuelType}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                error={
                  formik.touched.fuelType && Boolean(formik.errors.fuelType)
                }
                helperText={formik.touched.fuelType && formik.errors.fuelType}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={translationsEn.vehicles.numberOfSeats}
                id="numberOfSeats"
                name="numberOfSeats"
                value={formik.values.numberOfSeats}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                error={
                  formik.touched.numberOfSeats &&
                  Boolean(formik.errors.numberOfSeats)
                }
                helperText={
                  formik.touched.numberOfSeats && formik.errors.numberOfSeats
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={translationsEn.vehicles.standingCapacity}
                id="standingCapacity"
                name="standingCapacity"
                value={formik.values.standingCapacity}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                error={
                  formik.touched.standingCapacity &&
                  Boolean(formik.errors.standingCapacity)
                }
                helperText={
                  formik.touched.standingCapacity &&
                  formik.errors.standingCapacity
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={translationsEn.vehicles.maximumSpeed}
                id="maxSpeed"
                name="maxSpeed"
                value={formik.values.maxSpeed}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                error={
                  formik.touched.maxSpeed && Boolean(formik.errors.maxSpeed)
                }
                helperText={formik.touched.maxSpeed && formik.errors.maxSpeed}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={translationsEn.vehicles.stationaryNoise}
                id="stationaryNoise"
                name="stationaryNoise"
                value={formik.values.stationaryNoise}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                error={
                  formik.touched.stationaryNoise &&
                  Boolean(formik.errors.stationaryNoise)
                }
                helperText={
                  formik.touched.stationaryNoise &&
                  formik.errors.stationaryNoise
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={translationsEn.vehicles.co2}
                id="co2Emission"
                name="co2Emission"
                value={formik.values.co2Emission}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                error={
                  formik.touched.co2Emission &&
                  Boolean(formik.errors.co2Emission)
                }
                helperText={
                  formik.touched.co2Emission && formik.errors.co2Emission
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={translationsEn.vehicles.ecoCategory}
                id="ecoCategory"
                name="ecoCategory"
                value={formik.values.ecoCategory}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                error={
                  formik.touched.ecoCategory &&
                  Boolean(formik.errors.ecoCategory)
                }
                helperText={
                  formik.touched.ecoCategory && formik.errors.ecoCategory
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={translationsEn.vehicles.ownerOIB}
                id="ownerOIB"
                name="ownerOIB"
                value={formik.values.ownerOIB}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                error={
                  formik.touched.ownerOIB && Boolean(formik.errors.ownerOIB)
                }
                helperText={formik.touched.ownerOIB && formik.errors.ownerOIB}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={translationsEn.vehicles.bodyType}
                id="bodyType"
                name="bodyType"
                value={formik.values.bodyType}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                error={
                  formik.touched.bodyType && Boolean(formik.errors.bodyType)
                }
                helperText={formik.touched.bodyType && formik.errors.bodyType}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={translationsEn.vehicles.purpose}
                id="purpose"
                name="purpose"
                value={formik.values.purpose}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                error={formik.touched.purpose && Boolean(formik.errors.purpose)}
                helperText={formik.touched.purpose && formik.errors.purpose}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={translationsEn.vehicles.firstRegistrationInCountry}
                id="firstRegistrationInCountry"
                name="firstRegistrationInCountry"
                value={formik.values.firstRegistrationInCountry}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                error={
                  formik.touched.firstRegistrationInCountry &&
                  Boolean(formik.errors.firstRegistrationInCountry)
                }
                helperText={
                  formik.touched.firstRegistrationInCountry &&
                  formik.errors.firstRegistrationInCountry
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={translationsEn.vehicles.permissiblePayload}
                id="permissiblePayload"
                name="permissiblePayload"
                value={formik.values.permissiblePayload}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                error={
                  formik.touched.permissiblePayload &&
                  Boolean(formik.errors.permissiblePayload)
                }
                helperText={
                  formik.touched.permissiblePayload &&
                  formik.errors.permissiblePayload
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={translationsEn.vehicles.length}
                id="length"
                name="length"
                value={formik.values.length}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                error={formik.touched.length && Boolean(formik.errors.length)}
                helperText={formik.touched.length && formik.errors.length}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={translationsEn.vehicles.width}
                id="width"
                name="width"
                value={formik.values.width}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                error={formik.touched.width && Boolean(formik.errors.width)}
                helperText={formik.touched.width && formik.errors.width}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={translationsEn.vehicles.height}
                id="height"
                name="height"
                value={formik.values.height}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                error={formik.touched.height && Boolean(formik.errors.height)}
                helperText={formik.touched.height && formik.errors.height}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={translationsEn.vehicles.tireSize}
                id="tyreSize"
                name="tyreSize"
                value={formik.values.tyreSize}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                error={
                  formik.touched.tyreSize && Boolean(formik.errors.tyreSize)
                }
                helperText={formik.touched.tyreSize && formik.errors.tyreSize}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={translationsEn.vehicles.make}
                id="model"
                name="model"
                value={formik.values.model}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                error={formik.touched.model && Boolean(formik.errors.model)}
                helperText={formik.touched.model && formik.errors.model}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={translationsEn.vehicles.KmOnInput}
                id="kmOnInputDay"
                name="kmOnInputDay"
                value={formik.values.kmOnInputDay}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                error={
                  formik.touched.kmOnInputDay &&
                  Boolean(formik.errors.kmOnInputDay)
                }
                helperText={
                  formik.touched.kmOnInputDay && formik.errors.kmOnInputDay
                }
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{ display: "flex", flexDirection: "row-reverse" }}
            >
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  fullWidth
                  sx={{
                    borderRadius: "15px",
                    width: "98%",
                    backgroundColor: themeOptions.palette.primary.main,
                    color: "white",
                    ":hover": {
                      background: themeOptions.palette.primary.hover,
                    },
                  }}
                >
                  {translationsEn.buttons.confirm}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="outlined"
                  color="primary"
                  type="submit"
                  fullWidth
                  sx={{ borderRadius: "15px", width: "98%" }}
                  onClick={() => handleClose()}
                >
                  {translationsEn.buttons.cancel}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </>
  );
}
