import {
  Paper,
  Typography,
  Button,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  CircularProgress,
  Autocomplete,
  FormControl,
  TextField,
  Grid,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import themeOptions from "../../style/componentsStyle";
import translationsEn from "../../i18n/translations_en";
import { useEffect, useState } from "react";
import { DatePicker } from "@mui/x-date-pickers";
import { getVehicles } from "../../api/vehiclesApi";
import { useNavigate } from "react-router-dom";

export default function VehiclesOverview() {
  const [dateFrom, setDateFrom] = useState<any>("");
  const navigate = useNavigate();
  const [tableData, setTableData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [vehicleSelected, setVehiclesSelected] = useState<any>();
  const [refresh, setRefresh] = useState<boolean>(true);
  const [status, setStatus] = useState("");
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const emptyRows = tableData
    ? 5 - tableData.slice(page * pageSize, page * pageSize + pageSize)!.length
    : 0;
  const [previewModal, setPreviewModal] = useState<boolean>(false);
  const [previewItem, setPreviewItem] = useState<any>({});

  const closeequipmentModal = () => {
    setVehiclesSelected(undefined);
    setRefresh(!refresh);
  };
  const statusList = [
    { name: "IN_USE", label: "In use" },
    { name: "FREE", label: "Free" },
    { name: "DELETED", label: "Deleted" },
  ];
  useEffect(() => {
    const criteria = {
      vehicleName: null,
      firstRegistrationYear: null,
      licencePlateNo: null,
      maximumAuthorizedMass: null,
      category: null,
      numberOfSeats: null,
      ecoCategory: null,
    };

    const fetchVehicles = async () => {
      try {
        const response = await getVehicles(criteria); // Criteria will be passed as query parameters
        setPage(0);
        setTableData(response.data.data);
      } catch (error) {
        console.error("Failed to fetch vehicles:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchVehicles();
  }, [status, dateFrom, refresh]);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const getStatusLabel = (name: string): string | undefined => {
    const status = statusList.find((item) => item.name === name);
    return status ? status.label : undefined;
  };

  return (
    <div
      style={{
        height: "100%",
        padding: "10px",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      <Paper
        style={{
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        variant="outlined"
      >
        <Typography
          style={{
            marginLeft: "10px",
            fontSize: "40px",
          }}
        >
          {translationsEn.vehicles.pageName}
        </Typography>
      </Paper>
      <Paper
        style={{
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
        }}
        variant="outlined"
      >
        <Box
          style={{
            padding: "10px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <FormControl
            style={{
              marginTop: "10px",
              marginRight: "10px",
              display: "flex",
              flexDirection: "row",
              gap: "10px",
            }}
          >
            <Autocomplete
              options={statusList}
              getOptionLabel={(option: any) => option.label}
              onChange={(event: any, value: any) => {
                setStatus(value?.name);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={translationsEn.equipment.equipmentStatus}
                />
              )}
              sx={{ width: "200px" }}
            />
            <DatePicker
              label={translationsEn.allTravelRequests.dateFrom}
              format="DD/MM/YYYY"
              onChange={(date: any) => {
                if (date) {
                  const selectedDate = new Date(date);
                  // Set the time to 22:00:00
                  selectedDate.setHours(22, 0, 0, 0);
                  const adjustedDate = new Date(
                    selectedDate.getTime() -
                      selectedDate.getTimezoneOffset() * 60000
                  );
                  setDateFrom(adjustedDate.toISOString());
                }
              }}
              sx={{ width: "200px" }}
              slotProps={{
                actionBar: {
                  actions: ["clear"],
                },
              }}
              onAccept={(newDate) => {
                if (newDate === null) setDateFrom(newDate);
              }}
            />
          </FormControl>
        </Box>
      </Paper>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <Grid style={{ display: "flex", justifyContent: "space-between" }}>
          <Box />
          <Button
            variant="contained"
            style={{
              margin: "10px",
              borderRadius: "20px",
              height: "50%",
            }}
            sx={{
              backgroundColor: themeOptions.palette.primary.main,
              color: "white",
              ":hover": {
                background: themeOptions.palette.primary.hover,
              },
              textTransform: "none",
            }}
            onClick={() => navigate("/newVehicle")}
          >
            {translationsEn.vehicles.createNewButton}
          </Button>
        </Grid>
        <TableContainer sx={{ maxHeight: 401 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  {translationsEn.vehicles.vehicleName}
                </TableCell>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  {translationsEn.vehicles.assignedUser}
                </TableCell>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  {translationsEn.vehicles.vehicleCategory}
                </TableCell>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  {translationsEn.vehicles.licencePlateNo}
                </TableCell>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  {translationsEn.vehicles.enviroment}
                </TableCell>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  {translationsEn.vehicles.maximumSpeed}
                </TableCell>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  {translationsEn.vehicles.vehicleStatus}
                </TableCell>

                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  {translationsEn.vehicles.addService}
                </TableCell>
                <TableCell align="center" style={{ fontWeight: "bold" }}>
                  {translationsEn.equipment.action}
                </TableCell>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  {translationsEn.vehicles.deleteVehicle}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody style={{ height: "344px" }}>
              {tableData.length === 0 && (
                <TableRow style={{ height: 344 }}>
                  <TableCell colSpan={9} align="center">
                    {loading ? (
                      <CircularProgress />
                    ) : (
                      <>{translationsEn.table.noData}</>
                    )}
                  </TableCell>
                </TableRow>
              )}
              {tableData
                .slice(page * pageSize, page * pageSize + pageSize)
                .map((dataRow: any, index: any) => (
                  <TableRow
                    onClick={(event: any) => {
                      if (
                        event.currentTarget
                          .querySelector(".exclude-click")
                          .contains(event.target)
                      ) {
                        return;
                      }
                      setPreviewItem(dataRow);
                      setPreviewModal(true);
                    }}
                    key={dataRow.id}
                    sx={{
                      cursor: "pointer",
                      "&:last-child td, &:last-child th": { border: 0 },
                      height: "20px",
                    }}
                  >
                    <TableCell align="left">{dataRow.vehicleName}</TableCell>
                    <TableCell align="left">{dataRow.vehicleUser}</TableCell>
                    <TableCell align="left">{dataRow.category}</TableCell>
                    <TableCell align="left">{dataRow.licencePlateNo}</TableCell>
                    <TableCell align="left">{dataRow.ecoCategory}</TableCell>
                    <TableCell align="left">{dataRow.maxSpeed}</TableCell>
                    <TableCell align="left">{dataRow.status}</TableCell>
                    <TableCell align="left" className="exclude-click">
                      <Button
                        style={{ padding: "5px" }}
                        sx={{
                          backgroundColor: themeOptions.palette.primary.main,
                          color: "white",
                          ":hover": {
                            background: themeOptions.palette.primary.hover,
                          },
                          textTransform: "none",
                        }}
                        onClick={() => {
                          console.log(
                            "Add service clicked for",
                            dataRow.vehicleName
                          );
                        }}
                      >
                        {translationsEn.vehicles.addService}
                      </Button>
                    </TableCell>

                    <TableCell align="left" className="exclude-click">
                      <Button
                        style={{ padding: "5px" }}
                        sx={{
                          background: themeOptions.palette.primary.main,
                          color: "white",
                          ":hover": {
                            background: themeOptions.palette.primary.hover,
                          },
                        }}
                        onClick={() => {
                          setVehiclesSelected(dataRow);
                          console.log("Edit clicked for", dataRow.vehicleName);
                        }}
                      >
                        <EditIcon />
                      </Button>
                    </TableCell>

                    {/* Delete button */}
                    <TableCell align="left" className="exclude-click">
                      <Button
                        style={{ padding: "5px" }}
                        sx={{
                          backgroundColor: themeOptions.palette.cancel.main,
                          color: "white",
                          ":hover": {
                            background: themeOptions.palette.cancel.hover,
                          },
                          textTransform: "none",
                        }}
                        onClick={() => {
                          // Handle delete action
                          console.log(
                            "Delete clicked for",
                            dataRow.vehicleName
                          );
                        }}
                      >
                        {translationsEn.vehicles.deleteVehicle}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              {tableData.slice(page * pageSize, page * pageSize + pageSize)
                .length !== 0 &&
                emptyRows > 0 && (
                  <TableRow style={{ height: 68 * emptyRows }}>
                    <TableCell colSpan={10} />
                  </TableRow>
                )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          colSpan={3}
          count={tableData.length}
          rowsPerPage={pageSize}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      {/* <EquipmentPreviewModal
        open={previewModal}
        closeModal={() => setPreviewModal(false)}
        data={previewItem}
      /> */}
    </div>
  );
}
