import APICall from "./axios";

export const getVehicles = (criteria: any) => {
  return APICall.post(`/api/vehicles/all`, criteria);
};

export const createVehicle = async ({
  vehicleName,
  licencePlateNo,
  firstRegistrationYear,
  vehicleOwner,
  vehicleUserId,
  brand,
  homologationType,
  commercialName,
  vinNo,
  maximumAuthorizedMass,
  curbWeight,
  lastRegistrationYear,
  category,
  numberOfAxles,
  displacement,
  engineHorsepower,
  fuelType,
  numberOfSeats,
  standingCapacity,
  maxSpeed,
  stationaryNoise,
  co2Emission,
  ecoCategory,
  ownerOIB,
  bodyType,
  purpose,
  firstRegistrationInCountry,
  permissiblePayload,
  length,
  width,
  height,
  tyreSize,
  model,
  kmOnInputDay,
}: {
  vehicleName: string;
  licencePlateNo: string;
  firstRegistrationYear: number;
  vehicleOwner: string;
  vehicleUserId: number;
  brand: string;
  homologationType: string;
  commercialName: string;
  vinNo: string;
  technicalMaxAllowedMass: number;
  maximumAuthorizedMass: number;
  curbWeight: number;
  lastRegistrationYear: number;
  category: string;
  numberOfAxles: number;
  displacement: number;
  engineHorsepower: number;
  fuelType: string;
  numberOfSeats: number;
  standingCapacity: number;
  maxSpeed: number;
  stationaryNoise: number;
  co2Emission: number;
  ecoCategory: number;
  ownerOIB: string;
  bodyType: string;
  purpose: string;
  firstRegistrationInCountry: string;
  permissiblePayload: number;
  length: number;
  width: number;
  height: number;
  tyreSize: number;
  model: string;
  kmOnInputDay: number;
}): Promise<any> => {
  try {
    const payload = {
      vehicleName,
      licencePlateNo,
      firstRegistrationYear,
      vehicleOwner,
      vehicleUserId,
      brand,
      homologationType,
      commercialName,
      vinNo,
      maximumAuthorizedMass,
      curbWeight,
      lastRegistrationYear,
      category,
      numberOfAxles,
      displacement,
      engineHorsepower,
      fuelType,
      numberOfSeats,
      standingCapacity,
      maxSpeed,
      stationaryNoise,
      co2Emission,
      ecoCategory,
      ownerOIB,
      bodyType,
      purpose,
      firstRegistrationInCountry,
      permissiblePayload,
      length,
      width,
      height,
      tyreSize,
      model,
      kmOnInputDay,
    };

    const response = await APICall.post<any>("/api/vehicles", payload);

    if (response.data) {
      return response.data;
    } else {
      throw new Error("Vehicles data is missing or in an unexpected format.");
    }
  } catch (error) {
    console.error(`Error creating comment`, error);
    throw error;
  }
};
